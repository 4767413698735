import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../core/services/auth.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { TranslateService } from '@ngx-translate/core'; // Importa TranslateService

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class SectionStaticHomeComponent implements OnInit {

  btnArray = [
    '../../../assets/svg/icon_bar_1.svg',
    '../../../assets/svg/icon_bar_2.svg',
    '../../../assets/svg/icon_bar_3.svg',
    '../../../assets/svg/icon_bar_4.svg',
    '../../../assets/svg/icon_bar_5.svg',
  ];

  // Variable para almacenar la referencia al modal
  private modalRef: NgbModalRef;
  
  // Variable para almacenar el idioma actual
  currentLanguage: string; 

  constructor(private modalService: NgbModal, private router: Router, private authService: AuthService, private httpService: CallhttpService, private configService: ConfigService, private translateService: TranslateService) { }

  ngOnInit(): void {
    // Inicializa el idioma actual
    this.currentLanguage = this.translateService.currentLang;
    // Suscríbete a los cambios de idioma
    this.translateService.onLangChange.subscribe(() => {
      this.currentLanguage = this.translateService.currentLang;
    });
  }

  // Método para abrir el modal
  openModal(content): void {
    this.modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  // Método para cerrar el modal
  closeModal(): void {
    this.modalRef.close();
  }

  goToWeAnalize(index) {
    this.router.navigate(['/weanalyze/' + index]);
  }

}
